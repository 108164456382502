module.exports = {
  // eslint-disable-next-line no-loss-of-precision
  MaxMintCount: 110,
  BurnCareAmout: 125,
  mintCount: 1,
  mintCost: 0.00,
  wlcost: 0,
  MaxMintAllow: 1,
  totalGas: 400000,
  ruthlessSocietyContractAddr: "0x983BA9beF60C4E9360a40813f7f6eEfC7cbD3F54",
};
