import { useState, useEffect } from "react";
import Countdown from "react-countdown";

import Button from "../common/button";
import SliderNFT from "./Slider";

import MintStyleWrapper from "./Mint.style";

import checkIcon from "../../assets/images/icon/mint-right-text-icon.svg";
import twitterIcon from "../../assets/images/icon/Twitter.svg";
import { GooSpinner } from "react-spinners-kit";

import config from "../../config/config";
import RuthlessABI from "../../assets/abis/abi.json";
import { useWeb3React } from "@web3-react/core";
import { errorAlert, successAlert } from "../common/toast";
const ethers = require("ethers");

const Mint = () => {
  const { account } = useWeb3React();

  const [whiteListState, setWhiteListState] = useState(true);
  const [loadingState, setLoadingState] = useState(false);
  const [mintCount, setMintCount] = useState(0);
  const [mintState, setMintState] = useState(true);
  const [whiteListCount, setWhiteListCount] = useState(0);
  const [totalRewardAmount, setTotalRewardAmount] = useState(0);
  const [TotalNftBalance, setTotalNftBalance] = useState(0);
  const [hasClaimedRewards, setHasClaimedRewards] = useState(false);

  

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const Signer = provider.getSigner();

  const ruthlessSocietyContract = new ethers.Contract(
    config.ruthlessSocietyContractAddr,
    RuthlessABI,
    Signer
  );

  const mintStateFunc = async () => {
    let balance = 0;
    if (account !== undefined) {
      balance = await ruthlessSocietyContract.totalSupply();
      const count = Number(balance.toString());
      setMintCount(count);
      if (count >= config.MaxMintCount) {
        setMintState(false);
      }
    }
  };

  const getWhiteListState = async () => {
    const state = await ruthlessSocietyContract.whiteList(account);
    setWhiteListCount(state);
  };

  const getTotalRewardAmount = async () => {
    const totalRewardAmount =
      await ruthlessSocietyContract.getTotalRewardForAddress(account);
	 const formattedTotalRewardAmount = (Number(totalRewardAmount / 1000000000000000000)).toFixed(2);
	setTotalRewardAmount(formattedTotalRewardAmount);
  };

  const getTotalNftBalance = async () => {
    const TotalNftBalance =
      await ruthlessSocietyContract.balanceOf(account);
	 const formattedTotalNftBalance = (Number(TotalNftBalance));
	setTotalNftBalance(formattedTotalNftBalance);
  };
	
  useEffect(() => {
    if (account) {
      mintStateFunc();
	  getWhiteListState();
	  getTotalRewardAmount();
	  getTotalNftBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const mint = async () => {
    setLoadingState(true);
    await ruthlessSocietyContract
      .mintWhiteList(config.mintCount, {
        gasLimit: config.totalGas,
      })
      .then((tx) => {
        tx.wait()
          .then(() => {
            setLoadingState(false);
            successAlert("Minted successful!");
            mintStateFunc();
            getWhiteListState();
          })
          .catch(() => {
            setLoadingState(false);
            errorAlert("Mint failed!");
          });
      })
      .catch(() => {
        setLoadingState(false);
        errorAlert("Mint failed!");
      });
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <h1 className="mt-3 text-4xl font-bold text-white"></h1>
      );
    } else {
      // Render a countdown
      return (
        <h1 className="mt-3 text-4xl font-bold text-white">
          {days}d : {hours}h : {minutes}m : {seconds}s
        </h1>
      );
    }
  };
	
  const claimAllFunc = async () => {
    await ruthlessSocietyContract.claimAllRewards().then((tx) => {
      tx.wait()
        .then(() => {
          setLoadingState(false);
          successAlert("Claim successful!");
        })
        .catch(() => {
          setLoadingState(false);
          errorAlert("Claim failed!");
        });
	});
	setHasClaimedRewards(true);
  };
	
  return (
    <MintStyleWrapper>
      <div className="container my-10">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="mint_left_content">
            <div className="mint_left_inner">
              <div className="mint_slider">
                <SliderNFT />
              </div>
              <ul className="z-50 mint_count_list">
                <li>
                  <h3 className="font-extrabold text-white">Minted </h3>
                  <h3 className="font-extrabold text-white">
                    {mintCount} / {config.MaxMintCount}
                  </h3>
                </li>
                <li>
                  <h3 className="font-extrabold text-white">Price</h3>
                  <h3 className="font-extrabold text-white">Free + gas </h3>
                </li>
                {whiteListCount !== 0 && (
                  <li>
                    <h3 className="font-extrabold text-white">
                      Mint left for you
                    </h3>
                    <h3 className="font-extrabold text-white">
                      {whiteListCount}
                    </h3>
                  </li>
                )}
              </ul>
              {mintState && whiteListCount !== 0 ? (
                <div className="relative w-full">
                   <Button lg onClick={() => mint()} variant="outline">
                    {" "}
                    Mint Poodle Gang
                    {loadingState && (
                      <span className="mx-3">
                        <GooSpinner size={27} />
                      </span>
                    )}
                  </Button>
                </div>
              ) : (
                <h1 className="font-extrabold text-center text-white uppercase">
                  This address is not whitelist address or you minted all!
                </h1>
              )}{" "}
              <br />
            </div>
          </div>
          <div className="mint_right_content">
            {mintState ? (
              <>
                <div className="content_header">
                  <h1 className="font-bold">Poodle Gang</h1>
                  <h3 className="text-lg font-extrabold text-white">
                    Total supply: 110 & max 1 mint per address
                  </h3>
                </div>
                <div className="mint_timer">
                  <h3 className="text-4xl font-extrabold text-white">
                    {whiteListState
                      ? "Whitelist Mint Starts in"
                      : "Whitelist mint LIVE"}
                  </h3>
                  <Countdown
                    date={"2023-04-08 09:00:00 UTC"}
                    intervalDelay={0}
                    precision={3}
                    onComplete={() => setWhiteListState(false)}
                    renderer={renderer}
                  />
                </div>
                <div className="content_footer">
                  <h1 className="font-bold">
					Free mint for whitelisted addresses only <br></br>
					There are 10 cartoon Poodle - next collection sneaks
                  </h1>
                </div>
              </>
            ) : (
              <h1 className="flex text-5xl font-bold tex">
                WE ARE SOLD OUT & Claim Royalties{" "}
                <span className="">
                  <img src={checkIcon} alt="icon" />
                </span>
              </h1>
            )}

            <div className="flex gap-3 mt-3">
              <Button lg variant="outline">
                <img src={twitterIcon} alt="icon" />
                <a
                  href="https://twitter.com/PoodleFLR"
                  target="_blank"
                  rel="noreferrer"
                >
                  PoodleCoin
                </a>
              </Button>
              <Button lg variant="outline">
                <img src={twitterIcon} alt="icon" />
                <a
                  href="https://twitter.com/AnimaloveNFT"
                  target="_blank"
                  rel="noreferrer"
                >
                  Animalove
                </a>
              </Button>
			</div>
			<div className="relative flex flex-col justify-start w-full mt-5">
				<h1 className="flex text-2xl font-bold">
				Total NFT: {TotalNftBalance} <br></br>		  
                Total Claimable Amount : {totalRewardAmount} FLR
              </h1>
              {totalRewardAmount > 0.000009 && !hasClaimedRewards && (
                <div className="relative">
                  <Button lg variant="outline" onClick={() => claimAllFunc()}>
                    Claim Rewards
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MintStyleWrapper>
  );
};

export default Mint;
